.contact-copy {
    font-size: 18px;
    cursor: copy;
}

.contact-copy-message {
    font-size: 12px;
    font-style: italic;
    margin-top: 8px;
    color: lightgray;
}