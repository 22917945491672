footer {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: unset;
}

@media only screen and (max-width: 675px) {
    footer {
        position: unset;
        left: unset;
        bottom: unset;
        width: 100%;
    }
}

.socials {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    list-style-type: none;
    margin: 5px;
}

.socials li {
    margin: 5px;
    display: inline;
}