/*@font-face {
    font-family: race;
    src: url('./fonts/RaceFeverPenPersonal.ttf');
    font-display: swap;
}

@font-face {
    font-family: stonehen;
    src: url('./fonts/stonehen.ttf');
    font-display: swap;
}*/

html, body {
    margin: 0;
    padding: 0;

    min-height: 100vh;
    max-width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;


}

body {
    width: 100%;
    /*background-image: url('./images/bg.webp');*/
    
    background-repeat: space;
    background-color: black;
}
#root {
    width: 100%;
    max-width: 100vw;
}
.App {
    width: 100%;
    max-width: 100vw;
}

section {
    min-width: 300px;
    width: 90%;
}

div {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

.video {
}
.video-glass {
    width: 100%;
    height: 720px;

    background-color: transparent;

    position: relative;
} 

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    min-width: 300px;
    min-height: 300px;
    width: 50vw;
    height: max(300px, 50vh)
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
  }

iframe {
    z-index: 0;
}


::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gainsboro;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: white;
}

h3, h4 {
    margin-bottom: 4px;
    color: lightgreen;
}

p {
    color: white;
    margin: 0;
}