.curtain {
    position: fixed;
 
    background-color: transparent;
    
    z-index: 999999;
   /*
    background: linear-gradient(black 49%, transparent 50%, black 51%);*/
}

.curtain-animation {
    animation: linear opacity .5s;
}
/*
cubic-bezier(1, .5, .5, 1) 
*/

@keyframes tv {
    0% {
        box-shadow: 0px 0px 2px black inset, 0px 0px 2px black inset, 0px 0px 0px black inset, 0px 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    10% {
        box-shadow: 0px 50vh 2px black inset, 0px -50vh 2px black inset, 0px 0px 0px black inset, 0px 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    25% {
        box-shadow: 0px 50vh 2px black inset, 0px -50vh 2px black inset, 10vw 0px 0px black inset, -10vw 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    30% {
        box-shadow: 0px 50vh 0px black inset, 0px -50vh 0px black inset, 50vw 0px 0px black inset, -50vw 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    50% {
        box-shadow: 0px 50vh 0px black inset, 0px -50vh 0px black inset, 50vw 0px 0px black inset, -50vw 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    70% {
        box-shadow: 0px 50vh 0px black inset, 0px -50vh 0px black inset, 50vw 0px 0px black inset, -50vw 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    75% {
        box-shadow: 0px 50vh 5px black inset, 0px -50vh 5px black inset, 10vw 0px 0px black inset, -10vw 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    95% {
        box-shadow: 0px 50vh 5px black inset, 0px -50vh 5px black inset, 0px 0px 0px black inset, 0px 0px 0px black inset;
        width: 100vw;
        height: 100vh;
    }
    100% {
        box-shadow: 0px 0px 2px black inset, 0px 0px 2px black inset, 0px 0px 0px black inset, 0px 0px 0px black inset;;
        width: 100vw;
        height: 100vh;
    }
}

@keyframes opacity {
    0% {
        background-color: black;
        opacity: 0;
        width: 100vw;
        height: 100vh;
    }
    45% {
        background-color: black;
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }
    55% {
        background-color: black;
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }
    100% {
        background-color: black;
        opacity: 0;
        width: 100vw;
        height: 100vh;
    }
}

@keyframes curtain {
    0% {
        opacity: .6;
        border-radius: 50%;
        width: 0vw;
        height: 0vh;
    }
    40% {
        border-radius: 50%;
        width: max(125vw,125vh);
        height: max(125vw,125vh);
    }
    50% {
        opacity: .99;
        border-radius: 0;
        width: max(125vw,125vh);
        height: max(125vw,125vh);
    }
    60% {
        border-radius: 50%;
        width: max(125vw,125vh);
        height: max(125vw,125vh);
    }
    100% {
        opacity: .6;
        border-radius: 50%;
        width: 0vw;
        height: 0vh;
    }
}