header {
    position: fixed;
    top: 0;
    z-index: 999999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.nav-white-blur {
    background-color: none;
    backdrop-filter: none;
}

.nav {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    min-height: 75px;
    transition: .25s;
}

.big-logo {
    width: 125px;
    margin-top: 5px;
    transition: .25s;
}
.lil-logo {
    width: 125px;
    margin-top: 5px;
    opacity: 0;
    transition: .25s;
}

.nav-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;

    width: 325px;
    height: 50px;

    color: gainsboro;
}

.nav-t > h1 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.nav-c {
    display: none;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    list-style-type: none;

    width: 250px;
    gap: 50px;
}

.nav-drawer {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;

    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.1);
    backdrop-filter: blur(10px);

    animation: openDrawer .25s forwards;
    z-index: 99999;
}

.drawer-button {
    font-size: 24px;
    position: fixed;
    left: 25px;
    cursor: pointer;
    transition: background-color .25s;
}

.bars-background {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(255,255,255,.1);
    backdrop-filter: blur(10px);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    position: fixed;
    left: 15px;
   
}

.drawer-button-x {
    font-size: 24px;
    position: fixed;
    left: 25px;
    top: 25px;

    cursor: pointer;
}

@keyframes openDrawer {
    0%   {width: 0px;}
    100% {width: 100vw;}
}
@keyframes closeDrawer {
    0%   {width: 300px;}
    100% {width: 0px;}
}

.drawer-closed {
    display: none;
}

.drawer-opened {
    display: flex;
}

.drawer-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.drawer-media {
    margin-top: 50px;
    font-size: 1.1rem;
    font-style: normal;
}


.drawer-media li {
    margin-bottom: 15px;
}
.drawer-media a {
    color: white;
    text-decoration: none;
}

.link {
    color: white;
    text-decoration: none;
}
.link:hover {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
}

.button {
    position: absolute;
    top:5px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;    
    margin: 20px;
}

.nav-link {
    font-size: 1.5rem;

    color: rgb(235, 235, 235);
    text-decoration: none; 
    text-transform:uppercase;
    border-bottom: 1px solid transparent;
    transition: .25s;
    text-shadow: rgb(50, 50, 50) -1.2px -1.2px 0px;
}

.nav-link:hover {
    filter: invert(25%);
}

@media only screen and (min-width: 768px) {
    nav {
        background-color: unset;
        backdrop-filter: unset;
    }
    .nav-c {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    
        margin: 0;
        padding: 0;
    
        list-style-type: none;
    
        gap: 50px
    }

    .social {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    
        margin: 2px 18px 5px 0px;
        width: 100%;
    
        visibility: visible;
    }

    .nav-drawer {
        display: none;
    }
    .drawer-button {
        display: none;
    }

    .nav-link {
        font-size: 1rem;
    }

    .big-logo {
        width: 225px;
        margin-top: 10px;
    }
    .lil-logo {
        opacity: 1;
        width: 125px;
        margin-top: 5px;
    }

    .nav-white-blur {
        background-color: rgba(255,255,255,.1);
        backdrop-filter: blur(10px);
    }
    
}