.services-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    margin-top: 100px;
}
.services-section-title {
    font-size: 2.5rem;
    color: whitesmoke;
    text-shadow: rgb(50, 50, 50) -2.25px -2.25px 0px;
}

.services {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-width: 300px;
    max-width: 700px;
    width: 90%;
    margin-bottom: 16px;
    gap: 20px;
    transition: .25s;
}
.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 196.41px;
    
    background-color: transparent;

    cursor: pointer;
    transition: .25s;
}

.services > p {
    text-align: justify;
}

.services-access {
    min-width: 300px;
    width: 50%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service:hover .service-container, .service:active .service-container {
    filter: invert(1) grayscale(100%) sepia(25%) hue-rotate(180deg) blur(8px);
}
.service:hover .half-color, .service:active .half-color {
    opacity: 0;
}
.service:hover .half-color:before, .service:active .half-color:before {
    opacity: 0;
}
.service:hover .service-description, .service:active .service-description {
    opacity: 1;
}

.service-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 19.2px;
    filter: invert(1) grayscale(100%) sepia(25%) hue-rotate(180deg) blur(1px);
    width: 300px;
    height: 186px;
    background-size:cover;
    transition: .25s;
}

.service-mixvocals {
    background-image: url('https://fiverr-res.cloudinary.com/images/t_medium7,q_auto,f_auto,q_auto,f_auto/gigs/360261616/original/9ee3ec94e85ba51e0a9e4f384521c4833e8ae144/mix-your-vocals-professionally-for-your-songs.jpg');
}
.service-mastersong {
    background-image: url('https://fiverr-res.cloudinary.com/images/t_medium7,q_auto,f_auto,q_auto,f_auto/gigs/361181586/original/f74464573bf4f7626968e36b043d0a1455ef534b/master-your-rap-hiphop-or-pop-song.jpg');
}
.service-feature {
    background-image: url('https://fiverr-res.cloudinary.com/images/t_medium7,q_auto,f_auto,q_auto,f_auto/gigs/361188821/original/fee1620f1cf2a5b1d6a43a35a1c9156182307f15/write-and-sing-the-chorus-of-your-song.jpg');
}
.service-writeverse {
    background-image: url('https://fiverr-res.cloudinary.com/images/t_medium7,q_auto,f_auto,q_auto,f_auto/gigs/361183936/original/e6765a57ff215dd8ef4c06667b4bb2c6280a38cd/write-your-pop-hiphop-or-rap-song.jpg');
}



.service-description {
    color: black;
    position: absolute;
    opacity: 0;
    transition: .25s;
    font-weight: bold;
    text-shadow: gainsboro -1.2px -1.2px 0px;
}
.service-title {
    display: inline-block !important;
    width:fit-content;
    font-size: 32px;
    font-style: italic;
    margin: 0;
    z-index: 1; 
}
.half-color { 
	position:relative;
 	color:black;
    transition: .25s;
}
.half-color::before{
	content: attr(attribute);
	color:gainsboro;
	position:absolute;
	top:0;
	left:0;
	height:50%;
	overflow:hidden;
    transition: .25s;
}

.fiverr-logo {
    width: 30px;
}
.services-link {
    display: flex;
    justify-content: row;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    margin-bottom: 24px;
    font-size: 20px;
    gap: 10px;
    padding: 8px 8px 8px 8px;
    border: 2px solid white;
    box-sizing: border-box;
    border-radius: 8px;
    color: white;
    transition: .25s;
}
.services-link:hover, .services-link:active {
    border: 8px solid black;
    background-color: white;
    
    color: black;
    outline: 1px solid white;
}
.services-link:hover p { 
    color: black;
}
.services-link p {
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .services-section {
        margin-top: 75px;
    }
}