:root {
    --gradient-blue: linear-gradient(rgb(21, 135, 226), rgb(28, 68, 132));
    --gradient-pink: linear-gradient(rgb(226, 46, 142), rgb(100, 13, 33));
}

.gradient-blue {
    background-image: var(--gradient-blue);
}
.gradient-pink {
    background-image: var(--gradient-pink);
}

a {
    color: whitesmoke;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 100%;

    background-image: linear-gradient(black, black);
    -webkit-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out;
}

.release {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;

    background-color: rgba(20, 20, 20,.85);
    border-radius: 15px;
}

.coverart {
    width: 300px;
    height: 300px;
    border-radius: 15px 0px 0px 15px;
}

.iframe {
    width: 300px;
    height: 300px;
    border-radius: 15px 0px 0px 15px;
}

.info {
    padding-left: 35px;
    padding-right: 40px;
    width: 225px;
    max-width: 300px !important;
}

.label {
    color: gainsboro;
    font-weight: bold;
    text-decoration: underline;
}

.track-title {
    transition: .25s;
    font-size: 1.75rem;
}
.track-artist {
    font-size: 1.25rem;
}
.track-lyrics {
    text-decoration: underline;
}

a {
    text-decoration: none;
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    height: 100px;
    width: 250px;
}

.app {
    width: 25px;
    border-radius: 50%;
}

.app-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
}

.arrow {
    position: absolute;
    left: unset;
    top: unset;
    bottom: 25px;
    cursor: pointer;
}



.btn-link {
    transition: .5s;
}
.btn-link:hover {
    filter:invert(100%)
}


.white-blur {
    background-color: rgba(255,255,255,.1);
    backdrop-filter: blur(10px);
}

.color-white {
    color: white;
}

.color-black {
    color: black;
}
.bgcolor-white {
    background-color: white;
}
.bgcolor-black {
    background-color: black;
}

.visible {
    opacity: 1;
    transition: .25s;
}
.invisible {
    opacity: 0;
    transition: .25s;
}

@media only screen and (max-width: 768px) {
    .release {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: fit-content;
        background-image: linear-gradient(rgb(10, 10,  10), rgba(20, 20, 20,.9));
    }

    .coverart {
        border-radius: 15px 15px 0px 0px;
    }

    .iframe {
        border-radius: 15px 15px 0px 0px;
    }

    .info {
        padding: 30px 0px 30px 0px;
        width: 270px;
    }

    .links {
        height: 80px;
        width: 280px;
    }

    .arrow {
        position: relative;
        bottom: unset;
        left: 15px;
        top: 1px;
        transform: rotate(270deg);
    }

    .services-section {
        margin-top: 75px;
    }
}